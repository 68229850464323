import './App.css';
import React from 'react'
import { Route, Routes } from 'react-router-dom';
import {Hero, Research} from './components'

function App() {
  return (
    <div className="w-full">
    <Routes>
        <Route path='/' element={<Hero/>}/>
        <Route path='/research' element={<Research/>}/>
    </Routes>
    </div>
  );
}

export default App;
