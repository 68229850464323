import React from 'react'
import img from '../assets/4.png'
import {Navbar} from '../components'
const Hero = () => {
    return (
        <div className={'mt-5'}>
            <Navbar/>
            <div className={'justify-items-center flex-row'}>
                <h1 className="text-black mb-2 text-4xl font-extrabold leading-none tracking-tight md:text-5xl lg:text-6xl text-center">
                AI System for the</h1>
                <h1 className={'mb-5 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white text-center'}><span className={'text-gradient'}>Diagnosis of Breast Cancer.</span></h1>
                <p className="mb-6 text-lg font-normal text-center text-gray-600">
                    Here at MEBS we focus on implementing AI to improve the efficiency of breast cancer diagnosis and prognosis.
                </p>

                <div class=" grid justify-items-center">
                    <a href="#"
                       className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
                        Read more</a>
                </div>

            </div>
            <div className={'flex justify-center mt-40'}>
                <div className={'w-[70%] flex'}>
                    <div className="w-[100%] flex-col">
                        <div className='items-center w-[100%]'>
                            <img src={img} alt="doc" className='w-[45%] relative float-left'/>
                        </div>
                        <div className='items-center center-vertical float-right'>
                            <span className='part-text mb-10 text-gradient'>
                                MAMMOGRAMS
                            </span>
                            <h1 className='flex-1 font-poppins font-semibold text-[45px]'>
                                <span className=''>
                                    MebsP1
                                </span>
                            </h1>
                            <p className={'text-black max-w-[500px] mt-5 mb-5'}>
                                MebsP1 was created to facilitate the doctors when
                                identifying breast tumors in mammograms. By taking a
                                mammographic image and passing it through the
                                MebsP1 system. It is capable to classify an image
                                within seconds. It is faster than any doctor in the
                                world.
                            </p>
                        </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Hero