import React from 'react'
import {Navbar} from '../components'
import img1 from '../assets/bt.jpeg'

const Research = () => {
    return (
        <div className={'mt-5'}>
            <Navbar/>
            <div>
                <h1 className={'text-center mb-20 font-poppins font-semibold text-[35px]'}>Research</h1>
            </div>
            <div className={'flex justify-center'}>
                <div
                    className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-md dark:border-gray-700">
                    <a href="#">
                        <img className="rounded-t-lg" src={img1} alt=""/>
                    </a>
                    <div className="p-5">
                        <a href="#">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight">
                                Augmentation Effect on Brain Tumor MRIs
                            </h5>
                        </a>
                        <p className="mb-3 font-normal text-gray-700">
                            This experiment endeavored to discover the effect of data augmentation
                            on brain tumor MRIs using Transfer Learning.
                        </p>
                        <a href="https://drive.google.com/file/d/1Z9d2Ln2lrw3l9cfUYvDhAKj4K8qtThTC/view?usp=sharing"
                           className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Read more
                            <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                      clip-rule="evenodd"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Research